import { render, staticRenderFns } from "./RecStorageAddr.vue?vue&type=template&id=3752bd30&scoped=true&"
import script from "./RecStorageAddr.vue?vue&type=script&lang=js&"
export * from "./RecStorageAddr.vue?vue&type=script&lang=js&"
import style0 from "./RecStorageAddr.vue?vue&type=style&index=0&id=3752bd30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3752bd30",
  null
  
)

export default component.exports